<style scoped>
a {
  text-decoration: none;
  color: inherit;
}
</style>

<style>
div.v-toolbar__content {
  max-width: 1399px;
  margin: auto;
}
</style>

<template>
  <div>
    <!-- For the Bug1828470 - Added Skip to main link -->
    <vue-skip-to to="#main" text="Skip to main content" v-shortkey.focus="['alt', 's']"/>
    <v-app-bar style="z-index: 9999;" flat>
      <v-toolbar-title style="display: contents;">
        <router-link :to="{ name: 'Home' }" class="grey--text">
          <v-row align="center" style="width: 180px" no-gutters>
          <v-img
              src="/logo.png"
              max-width="54"
              aspect-ratio="1"
              class="d-inline mr-2"
            /><span style="color: #666666">EOS Insights</span>
          </v-row>
        </router-link>
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="hidden-md-and-up">
        <v-spacer />
        <v-menu
          bottom
          left
          attach
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              id="hiddenButton"
              icon
              v-bind="attrs"
              v-on="on"
              v-on:change="handleFocus"
            >
              <v-icon>$bars</v-icon>
            </v-btn>
          </template>
          <v-list id="burgerMenu" dense nav v-on:change="handleFocus" style="max-height: 250px" class="overflow-y-auto">
              <v-list-item :to="{ name: 'CustomerLookup' }" :disabled="disabled" v-on:keydown.tab="menuTabPressed">
                  Customer Lookup
              </v-list-item>
              <v-list-item :to="{ name: 'CustomerDetails' }" :disabled="disabled">
                  Customer Details
              </v-list-item>
              <v-list-item :to="{ name: 'ReportBuilder' }" :disabled="disabled">
                  Report Builder
              </v-list-item>
              <v-list-item :to="{ name: 'Dashboard' }" :disabled="disabled">
                  Regions (PBI)
              </v-list-item>
              <v-list-item :to="{ name: 'Reports' }" :disabled="disabled" >
                  Saved Reports
              </v-list-item>
              <v-list-item :to="{ name: 'Timeline' }" :disabled="disabled">
                  Timeline
              </v-list-item>
              <v-list-item :to="{ name: 'FAQ' }" :disabled="disabled">
                  FAQ
              </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-toolbar-items>
          <!-- <v-divider vertical />
          <v-btn text :to="{ name: 'Home' }"
            >Home</v-btn
          > -->
          <v-divider vertical />
          <v-btn text :to="{ name: 'CustomerLookup' }" :disabled="disabled" class="border-on-focus">
              <span v-if="$vuetify.breakpoint.md" style="width: 75px; white-space: normal;">Customer Lookup</span>
              <span v-else>Customer Lookup</span>
          </v-btn>
          <v-divider vertical />
          <v-btn text :to="{ name: 'CustomerDetails' }" :disabled="disabled" class="border-on-focus">
              <span v-if="$vuetify.breakpoint.md" style="width: 75px; white-space: normal;">Customer Details</span>
              <span v-else>Customer Details</span>
          </v-btn>
          <v-divider vertical />
          <v-btn text :to="{ name: 'ReportBuilder' }" :disabled="disabled" class="border-on-focus">
              <span v-if="$vuetify.breakpoint.md" style="width: 75px; white-space: normal;">Report Builder</span>
              <span v-else>Report Builder</span>
          </v-btn>
          <v-divider vertical />
          <v-btn text :to="{ name: 'Dashboard' }" :disabled="disabled" class="border-on-focus">
              <span v-if="$vuetify.breakpoint.md" style="width: 75px; white-space: normal;">Regions (PBI)</span>
              <span v-else>Regions (PBI)</span>
          </v-btn>
          <v-divider vertical />
          <v-btn text :to="{ name: 'Reports' }" :disabled="disabled" class="border-on-focus">
              <span v-if="$vuetify.breakpoint.md" style="width: 75px; white-space: normal;">Saved Reports</span>
              <span v-else>Saved Reports</span>
          </v-btn>
          <v-divider vertical />
          <v-btn text :to="{ name: 'Timeline' }" :disabled="disabled" class="border-on-focus">
              <span v-if="$vuetify.breakpoint.md" style="width: 75px; white-space: normal;">Timeline</span>
              <span v-else>Timeline</span>
          </v-btn>
          <v-divider vertical />
          <v-btn text :to="{ name: 'FAQ' }" :disabled="disabled" class="border-on-focus"
            >FAQ</v-btn
          >
          <v-divider vertical />
        </v-toolbar-items>
        <v-spacer  />
      </template>
      <!-- <v-btn icon small download href="./user_guide.docx">
        <v-icon small>help</v-icon>
      </v-btn> -->
      <v-toolbar-title>
        <img v-if="showPhoto" style="height: 48px; width: 48px; border-radius: 50%;" :src="photo" />
        <v-icon v-else>$user</v-icon>
        <!-- <span>Welcome {{ userName }}</span
            > -->
        <!-- <v-menu nudge-bottom="45">
          <template #activator="{ on }">
            <span style="cursor: pointer" v-on="on"
              >Welcome {{ userName }}</span
            >
          </template>
          <v-list>
            <v-list-item @click="logout">
              <v-list-item-icon>
                <v-icon>$logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu> -->
      </v-toolbar-title>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  created() {
    window.addEventListener('resize', this.setMenuSize)
    const hiddenButton = document.getElementById('hiddenButton')
    if (hiddenButton != null) {
      hiddenButton.addEventListener('change', this.handleFocus)
    }
  },
  props: {
    disabled: Boolean
  },
  components: {
  },
  data: () => ({
  }),
  computed: {
    userName() {
      const account = this.$auth.getAccount()

      if (account) {
        let name = account.name

        if (account.idTokenClaims) {
          const givenNameClaim = account.idTokenClaims.given_name
          if (givenNameClaim) {
            name = givenNameClaim
          }
        }

        return name
      }

      return ''
    },
    showPhoto() {
      return this.$store.getters['User/photo'] !== null
    },
    photo() {
      // const urlCreator = window.URL || window.webkitURL
      // console.log(this.$store.getters['User/photo'])
      // const imageUrl = urlCreator.createObjectURL(this.$store.getters['User/photo'])
      // console.log(imageUrl)
      return 'data:image/jpeg;base64,' + this.$store.getters['User/photo']
    }
  },
  methods: {
    menuTabPressed(event) {
      const menu = this.$refs[event.target.dataset.menu]
      console.debug('inside menuTabPressed.')
      // make sure children are evaluated
      menu.getTiles()
      // In most cases, we want the event to be stopped,
      // however, in the cases where we tab 'out of' the menu,
      // we want to propagate the event.
      let propagate = false

      if (menu.isActive) {
        if (event.shiftKey) {
          if (menu.listIndex <= 0) {
            menu.isActive = false
            propagate = true
          } else {
            menu.prevTile()
          }
        } else {
          if (menu.listIndex < 0) {
            menu.firstTile()
          } else if (menu.listIndex + 1 >= menu.tiles.length) {
            menu.isActive = false
            propagate = true
          } else {
            menu.nextTile()
          }
        }
      } else {
        propagate = true
      }
      if (!propagate) {
        event.preventDefault()
      }
    },
    handleFocus() {
      const btnElement = document.getElementById('hiddenButton')
      var focusedItem = btnElement.getAttribute('aria-activedescendant')
      console.log('Finally! Results: ' + focusedItem)
      const elementA = document.getElementById(focusedItem)
      elementA.scrollIntoView(false)
    },
    setMenuSize() {
      const element = document.getElementById('burgerMenu')
      if (element != null) {
        var heightString = ''
        var height = Math.min(350, window.innerHeight)
        heightString = heightString.concat(height).concat('px')
        element.style.maxHeight = heightString
        if (height < 350) {
          element.class = 'overflow-y-auto'
        } else {
          element.class = 'overflow-y-hidden'
        }
      }
    },
    logout() {
      this.$store.dispatch('Auth/logout')
    }
  }
}
</script>
<style>
    .border-on-focus::before {
      border: 2px solid primary !important
    }
    /* For the Bug1828470 - Adjusting Styles for Skip to main link */
    .vue-skip-to {
      background-color: #f5f5f5 !important;
      text-align: center;
      padding: 1.1em !important;
      width: 100%;
      z-index: 10000000 !important;
    }
    /* For the Bug 1828460 & 1828462 - Adjusted height for Hamburger Menu ities */
    div#burgerMenu.v-list--dense .v-list-item {
      min-height: 32px;
    }
</style>
